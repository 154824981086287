<template>
<v-app>
<v-main style="height:100%;">
<v-container fluid style="height:100%">
  <v-row class="auth-view" style="height:100%">
    <v-col class="auth-left hidden-sm-and-down" cols="12" sm="4">
      

      <v-row style="height:100%;" :align="'center'">
        <v-col sm="12" align-self="start"><h3 style="margin-top:20px">THE NETWORK HUB</h3></v-col>
        <v-col sm="12" align-self="center">
          <h2>We have great empathy for founders. We are them!</h2>
          <h3 class="sub">connect. create. collaborate.</h3>
        </v-col>
        <v-col sm="12" cols="12" style="text-align:center;" align-self="end">
          <h3 class="sub1">Great businesses practice efficiency</h3>
          <!--<img src="../assets/logos.png" />-->
        </v-col>
      </v-row>
    </v-col>
    <v-col class="auth-right" cols="12" sm="12" md="8">
      <v-row justify="center" style="height:100%">
        <v-col cols="12" xs="12" align-self="start"><h4 class="title text-center hidden-md-and-up">THE NETWORK HUB</h4></v-col>
        <v-col cols="12" xs="12" align-self="center">
          <v-row justify="center">
            <v-col cols="12" xs="12" md="6">
              <h2 class="title">Sign in to The Network Hub</h2>
              <h4 class="subtitle">Enter your details below</h4>
              <v-form ref="form" @submit.prevent="loginHandler">
                <v-text-field
                  v-model="email"
                  label="Email address"
                  placeholder=""
                  outlined
                  required
                  autocomplete="email"
                ></v-text-field>
                <v-text-field
                  v-model="password"
                  label="Password"
                  placeholder=""
                  outlined
                  type="password"
                  required
                  autocomplete="current-password"
                ></v-text-field>
                <v-row align="center" justify="space-between" >
                  <v-col>
                  <a href="">Forgot password?</a>
                  </v-col>
                  <v-col style="text-align:right;">
                  <v-btn type="submit" depressed color="primary">SIGN IN</v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>
        </v-col>
        <v-col align-self="end"></v-col>
      </v-row>
    </v-col>
  </v-row>
</v-container>
</v-main>
</v-app>
</template>
<script>
//import axios from 'axios'
import {mapActions} from 'vuex'
import {VENDOR_LOGIN_MUTATION} from '../graphql/mutations'
import {onLogin} from '../vue-apollo'

export default {
  name: 'Login',

  data:() => ({
    email: null,
    password: null,
    loading: false
  }),

  methods: {
    ...mapActions(['login']),
    async loginHandler() {
      const {email, password} = this
      /*
      const success = await this.login({email, password})
      console.log('success', success)
      */
     this.$apollo.mutate({ mutation: VENDOR_LOGIN_MUTATION, variables: { input: {email, password} } })
      .then((response) => {
        console.log(response)
        const {user, access_token} = response.data.vendorLogin
        onLogin(this.$apolloProvider.defaultClient, access_token)
          .then(() => {
            this.$store.commit('SET_USER', {user, access_token})
            this.$router.push('/orders/create')
          })
      })
    },
  }
}
</script>

<style lang="scss">
$color-pack: false;

/*@import '~vuetify/src/styles/main.sass';*/

h2 {
  /* Desktop/Heading 2/Bold (32|16) */
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 124%;
  /* or 40px */
}
h3 {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 132%;
}
h4 {
  /* Desktop/Heading 4/Regular (16|8) */
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  /* identical to box height, or 24px */
}
h2.title {
  color: #1D3557
}
h4.subtitle {
  color:#323232;
  margin-bottom: 24px;
}
.auth-left {
  background-image: url(../assets/login.png);
  background-position: top;
  background-size: cover;
  position: relative;
}
.auth-view .auth-left h3, .auth-view .auth-left h2 {
  color:#fff;
  text-align: center;
}
.auth-view .auth-left h3.sub {
  font-weight: 300;
  margin-top: 48px;
}
.auth-view .auth-left h3.sub1 {
  font-size: 18px;
  color: #E6E6E6;
  opacity: 0.9;
}
.container {
  padding-top: 0;
  padding-bottom: 0;
}
</style>